// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4CAF50', // primary-green
    },
    secondary: {
      main: '#388E3C', // secondary-green
    },
    error: {
      main: '#f44336',
    },
    warning: {
      main: '#ff9800',
    },
    info: {
      main: '#2196f3',
    },
    success: {
      main: '#66BB6A', // accent-green
    },
    background: {
      default: '#F1F1F1', // light-gray
      paper: '#FFFFFF', // white
    },
    text: {
      primary: '#212121', // black
      secondary: '#757575', // dark-gray
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

export default theme;
