import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import BusinessIcon from "@mui/icons-material/Business";
import MuiAlert from "@mui/material/Alert";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";

import {
	TextField,
	Checkbox,
	FormControlLabel,
	Button,
	Container,
	Box,
	Typography,
	Grid,
	Card,
	CardActionArea,
	CardContent,
	Snackbar,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import theme from "../../assets/theme";
import Cookies from "js-cookie";

function VehicleRegistration() {
	const { uid } = useParams();
	const [formData, setFormData] = useState({
		registrationNumber: "",
		contact: "",
		isContactWhatsapp: false,
		alternateContact: "",
		isAlternateContactWhatsapp: false,
		emergencyContact: "",
		uid: uid || "", // Set the uid from URL
		vehicleType: "PERSONAL",
	});
	const [notification, setNotification] = useState({
		show: false,
		message: "",
		type: "success",
	});
	const [otpPopupVisible, setOtpPopupVisible] = useState(false);
	const [otp, setOtp] = useState("");
	const [phoneLoginResponse, setPhoneLoginResponse] = useState(null);
	const [isUidValid, setIsUidValid] = useState(null);
	const [errors, setErrors] = useState({
		contact: "",
		emergencyContact: "",
	});
	const navigate = useNavigate();
	const headingRef = useRef(null); // Reference to the heading
	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});
	const verifyOtpBtnRef = useRef(null);
	const inputOtpRef = useRef(null);

	useEffect(() => {
		// Call the API to check UID status
		const checkUidStatus = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/uid-status/${uid}`
				);
				const data = await response.json();

				if (response.ok) {
					if (data.isUsed) {
						navigate(`/inform-vehicle-owner/${uid}`);
					} else {
						setIsUidValid(true);
					}
				} else {
					setNotification({
						show: true,
						message: "Error checking UID status",
						type: "error",
					});
				}
			} catch (error) {
				setNotification({
					show: true,
					message: "Network error",
					type: "error",
				});
			}
		};

		checkUidStatus();
	}, [uid, navigate]);

	useEffect(() => {
		// Scroll to the heading when the page loads
		if (headingRef.current) {
			headingRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	useEffect(() => {
		if (otpPopupVisible) {
			if (verifyOtpBtnRef.current) {
				verifyOtpBtnRef.current.scrollIntoView({ behavior: "smooth" });
			}
			if (inputOtpRef.current) {
				inputOtpRef.current.focus();
			}
		}
	}, [otpPopupVisible]);

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData({
			...formData,
			[name]: type === "checkbox" ? checked : value,
		});
		setErrors({ ...errors, [name]: "" }); // Clear the error when the user starts typing
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let hasError = false;
		const newErrors = { contact: "", emergencyContact: "" };

		// Validate contact number
		if (formData.contact.length !== 10) {
			newErrors.contact = "Contact number must be exactly 10 digits.";
			hasError = true;
		}

		// Validate emergency contact number
		if (formData.emergencyContact.length !== 10) {
			newErrors.emergencyContact =
				"Emergency contact number must be exactly 10 digits.";
			hasError = true;
		}

		if (hasError) {
			setErrors(newErrors);
			return; // Stop the form submission
		}

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/login/phone-login-initiate`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ phone: formData.contact }),
				}
			);
			if (response.ok) {
				setNotification({
					show: true,
					message: "Message sent successfully",
					type: "success",
				});
			} else {
				const data = await response.json();
				setNotification({
					show: true,
					message: data.message || "Error informing vehicle owner",
					type: "error",
				});
			}

			const data = await response.json();

			if (response.ok) {
				setPhoneLoginResponse(data);
				setOtpPopupVisible(true);
				setNotification({
					show: true,
					message: "OTP sent to your phone",
					type: "success",
				});
			} else {
				setNotification({
					show: true,
					message: data.message || "Error initiating phone login",
					type: "error",
				});
			}
		} catch (error) {
			setNotification({
				show: true,
				message: "Network error",
				type: "error",
			});
		}
	};

	const handleOtpSubmit = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/login/phone-login-verify`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						...phoneLoginResponse,
						verificationCode: otp,
					}),
				}
			);

			const data = await response.json();

			if (response.ok && data.jwtToken) {
				Cookies.set("jwtToken", data.jwtToken); // Store JWT token in cookies
				setOtpPopupVisible(false); // Close OTP popup
				setNotification({
					show: true,
					message: "Login successful",
					type: "success",
				});

				// Proceed with submitting the vehicle data
				await submitVehicleData();
			} else if (data.jwtToken === null) {
				// OTP is incorrect, show error and allow the user to try again
				setNotification({
					show: true,
					message: "Incorrect OTP, please try again",
					type: "error",
				});
			} else {
				// Other error case
				setNotification({
					show: true,
					message: data.message || "Error verifying OTP",
					type: "error",
				});
			}
		} catch (error) {
			setNotification({
				show: true,
				message: "Network error",
				type: "error",
			});
		}
	};

	const submitVehicleData = async () => {
		try {
			const token = Cookies.get("jwtToken");

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/vehicle`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(formData),
				}
			);

			const data = await response.json();

			if (response.ok) {
				setNotification({
					show: true,
					message: "Vehicle registered successfully",
					type: "success",
				});
				setTimeout(() => {
					navigate("/user-portal");
				}, 2000);
			} else {
				setNotification({
					show: true,
					message: data.message || "Error registering vehicle",
					type: "error",
				});
			}
		} catch (error) {
			setNotification({
				show: true,
				message: "Network error",
				type: "error",
			});
		}
	};

	const handleOtpChange = (e) => {
		setOtp(e.target.value);
	};

	const handleCloseNotification = () => {
		setNotification({ show: false, message: "", type: "" });
	};

	const handleVehicleTypeSelect = (type) => {
		setFormData({ ...formData, vehicleType: type });
	};

	if (isUidValid === null) {
		return (
			<ThemeProvider theme={theme}>
				<Container maxWidth="sm">
					<Box textAlign="center" mt={5}>
						<Typography variant="h5" color="textSecondary">
							Checking Vehicle status...
						</Typography>
					</Box>
				</Container>
			</ThemeProvider>
		);
	}

	return (
		<ThemeProvider theme={theme}>
			<Container maxWidth="sm">
				<Box
					textAlign="center"
					mb={3}
					onClick={() => navigate("/user-portal")} // Add the navigate function here
					sx={{ cursor: "pointer" }} // Add a pointer cursor to indicate it's clickable
				>
					<Typography variant="h4" color="primary">
						Syfr.live
					</Typography>
				</Box>

				<Typography
					variant="h5"
					gutterBottom
					style={{ fontWeight: "bold" }}
					color="secondary"
				>
					Vehicle Registration
				</Typography>
				<Typography
					variant="h5"
					gutterBottom
					sx={{
						color: "#757575",
						fontSize: "14px",
						marginBottom: "18px",
					}}
				>
					Fill the details to Proceed
				</Typography>
				<form onSubmit={handleSubmit}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									gap: 1,
								}}
							>
								<TextField
									fullWidth
									label="Vehicle Registration Number"
									id="registrationNumber"
									name="registrationNumber"
									value={formData.registrationNumber}
									onChange={handleChange}
									inputProps={{
										style: {
											textTransform: "uppercase",
											letterSpacing: "0.2em",
										},
										pattern: "[A-Z0-9]",
										maxLength: 15,
									}}
									required
								/>

								<Tooltip
									title={
										<Box
											sx={{
												width: "100%",
												maxWidth: 200,
											}}
										>
											<img
												src="https://i.ibb.co/fnJCyrV/Group-5fullnumberimage.png"
												alt="Vehicle Registration Example"
												style={{ width: "100%" }} // Makes image responsive
											/>
										</Box>
									}
									placement="right"
									arrow
									sx={{
										maxWidth: 250, // Limit Tooltip width
									}}
									PopperProps={{
										modifiers: [
											{
												name: "offset",
												options: {
													offset: [0, 8], // Adjust the tooltip position if needed
												},
											},
										],
									}}
								>
									<IconButton aria-label="info">
										<InfoIcon />
									</IconButton>
								</Tooltip>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Contact"
								id="contact"
								name="contact"
								type="number"
								value={formData.contact}
								onChange={handleChange}
								error={Boolean(errors.contact)}
								helperText={errors.contact}
								required
								onInput={(e) => {
									e.target.value = Math.max(
										0,
										parseInt(e.target.value)
									)
										.toString()
										.slice(0, 10);
								}}
								InputProps={{
									startAdornment: (
										<Typography variant="h6" sx={{ mr: 1 }}>
											+91
										</Typography>
									),
									inputMode: "numeric", // Open numeric keypad
									pattern: "[0-9]*", // Restrict to numbers only
								}}
								sx={{ mb: 1 }} // Reduce space below this input field
							/>
						</Grid>

						<div
							style={{
								paddingLeft: "24px",
								paddingRight: "16px",
								marginTop: "5px",
								display: "flex",
								alignItems: "center",
							}}
						>
							<WhatsAppIcon
								style={{
									fontSize: "14px",
									color: "grey",
									marginRight: "5px",
								}}
							/>
							<span style={{ fontSize: "12px", color: "grey" }}>
								Please enter a WhatsApp contact number.
							</span>
						</div>

						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Emergency Contact"
								id="emergencyContact"
								name="emergencyContact"
								type="number"
								value={formData.emergencyContact}
								onChange={handleChange}
								error={Boolean(errors.emergencyContact)}
								helperText={errors.emergencyContact}
								onInput={(e) => {
									e.target.value = Math.max(
										0,
										parseInt(e.target.value)
									)
										.toString()
										.slice(0, 10);
								}}
								InputProps={{
									startAdornment: (
										<Typography variant="h6" sx={{ mr: 1 }}>
											+91
										</Typography>
									),
									inputMode: "numeric", // Open numeric keypad
									pattern: "[0-9]*", // Restrict to numbers only
								}}
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography
								variant="h6"
								gutterBottom
								sx={{ mt: 2, mb: 1 }}
								color="secondary"
							>
								Select Vehicle Type
							</Typography>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<Card
										onClick={() =>
											handleVehicleTypeSelect("PERSONAL")
										}
										sx={{
											cursor: "pointer",
											border:
												formData.vehicleType ===
												"PERSONAL"
													? "2px solid #00796b"
													: "1px solid #ccc",
										}}
									>
										<CardActionArea>
											<CardContent>
												<Typography
													variant="h6"
													color="textPrimary"
													textAlign="center"
													fontSize="small"
													display="flex"
													alignItems="center"
													justifyContent="center"
												>
													<DirectionsCarIcon
														fontSize="small"
														sx={{ marginRight: 1 }}
													/>
													PERSONAL
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Grid>
								<Grid item xs={6}>
									<Card
										onClick={() =>
											handleVehicleTypeSelect(
												"COMMERCIAL"
											)
										}
										sx={{
											cursor: "pointer",
											border:
												formData.vehicleType ===
												"COMMERCIAL"
													? "2px solid #00796b"
													: "1px solid #ccc",
										}}
									>
										<CardActionArea>
											<CardContent>
												<Typography
													variant="h6"
													color="textPrimary"
													textAlign="center"
													fontSize="small"
													display="flex"
													alignItems="center"
													justifyContent="center"
												>
													<BusinessIcon
														fontSize="small"
														sx={{ marginRight: 1 }}
													/>
													COMMERCIAL
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Box textAlign="right" mt={2}>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							endIcon={<ArrowForwardIcon />}
							style={{
								color: "white",
								fontSize: "16px",
								marginTop: "22px",
							}}
						>
							Send OTP
						</Button>
					</Box>
				</form>
				{otpPopupVisible && (
					<Box mt={3}>
						<Typography
							variant="h6"
							color="secondary"
							textAlign="center"
						>
							Enter OTP
						</Typography>
						<Box
							display="flex"
							justifyContent="center"
							mt={2}
							gap={2}
						>
							<TextField
								fullWidth
								label="OTP"
								id="otp"
								name="otp"
								value={otp}
								onChange={handleOtpChange}
								inputRef={inputOtpRef}
								inputProps={{
									maxLength: 4,
									inputMode: "numeric",
									style: {
										textAlign: "center",
										letterSpacing: "0.5em",
									}, // Space between digits
								}}
							/>
						</Box>
						<Box textAlign="center" mt={2}>
							<Button
								variant="outlined"
								ref={verifyOtpBtnRef}
								onClick={handleOtpSubmit}
								sx={{
									color: "#388e3c",
									border: "2px solid #388e3c",
									backgroundColor: "#ffffff",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									fontSize: "16px",
									fontWeight: "bold",
									padding: "8px 16px",
									"&:hover": {
										backgroundColor: "#e8f5e9",
										borderColor: "#388e3c",
									},
								}}
							>
								Verify OTP
							</Button>
						</Box>
					</Box>
				)}
			</Container>
			<Snackbar
				open={notification.show}
				autoHideDuration={3000}
				onClose={handleCloseNotification}
				anchorOrigin={{ vertical: "top", horizontal: "center" }} // Show notification at the top
			>
				<Alert
					onClose={handleCloseNotification}
					severity={notification.type}
				>
					{notification.message}
				</Alert>
			</Snackbar>
		</ThemeProvider>
	);
}

export default VehicleRegistration;
