// PhoneNumberInput.js
import React from "react";
import styles from "./style.module.css";
import apis from "../../assets/apis";

const PhoneNumberInput = ({ setOtpSent, phone, setPhone, setOtpApiBody, setNotification }) => {
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10 && /^\d*$/.test(value)) {
      setPhone(value);
    }
  };

  const sendOtp = async () => {
    if(phone.length < 10) return;
    try {
      const response = await fetch(
        apis.LOGIN_WITH_PHONE_NUMBER,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ phone: `91${phone}` }),
        }
      );

      console.log(response.ok)
      if (response.ok) {
        setNotification({
          show: true,
          message: "OTP sent successfully",
          severity: 'success',
        });
        const body = await response.json()
        setOtpApiBody(body);
        setOtpSent(true); // Notify parent component that OTP was sent
      } else {
        const data = await response.json();
        setNotification({
          show: true,
          message: data.message || "Error sending OTP",
          severity: 'warning',
        });
      }
    } catch (error) {
      setNotification({
        show: true,
        message: "Network error",
        severity: 'warning',
      });
    }
  };

  return (
    <div className={styles.loginForm}>
      <div className={styles.heading1}>Enter Your Mobile Number</div>
      <div className={styles.heading2}>We will send you a verification code</div>
      <div className={styles.inputContainer}>
        <span>+91</span>
        <input
          type="number"
          value={phone}
          onChange={handlePhoneChange}
          placeholder="Phone No."
          maxLength={10}
          required
          className={styles.input_number}
        />
      </div>
      <button type="submit" className={styles.button_submit} onClick={sendOtp}>Send OTP</button>
    </div>
  );
};

export default PhoneNumberInput;
