import React, { useState } from "react";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";
import OtpInput from "../../components/OtpInput/OtpInput";
import styles from "./Login.module.css";
import CustomAlert from "../../components/CustomAlert/CustomAlert";
import { Typography, Box } from "@mui/material";
import theme from "../../assets/theme"; // Import your MUI theme
import { ThemeProvider } from "@mui/material/styles"; // Wrap with ThemeProvider

const Login = () => {
	const [otpSent, setOtpSent] = useState(false);
	const [phone, setPhone] = useState("");
	const [otpApiBody, setOtpApiBody] = useState(null);
	const [notification, setNotification] = useState({
		show: false,
		message: "",
		severity: "warning",
	});

	return (
		<ThemeProvider theme={theme}>
			{notification.show && (
				<CustomAlert
					message={notification.message}
					severity={notification.severity}
					onClose={() =>
						setNotification({ ...notification, show: false })
					}
				/>
			)}
			<Box className={styles.loginWrapper}>
				<Box textAlign="center" mb={2} mt={2}>
					<Typography variant="h4" color="primary">
						Syfr.live
					</Typography>
				</Box>
				<Box sx={{ mb: 2 }}>
					{!otpSent ? (
						<PhoneNumberInput
							setOtpSent={setOtpSent}
							phone={phone}
							setPhone={setPhone}
							setOtpApiBody={setOtpApiBody}
							setNotification={setNotification}
						/>
					) : (
						<OtpInput
							phone={phone}
							setPhone={setPhone}
							otpApiBody={otpApiBody}
							setOtpApiBody={setOtpApiBody}
							setNotification={setNotification}
						/>
					)}
				</Box>
				<div className={styles.termsAndConditions}>
					By Logging in you are accepting our{" "}
					<a href="/terms-and-conditions">Terms and conditions</a>
				</div>
			</Box>
		</ThemeProvider>
	);
};

export default Login;
