import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";

export default function VehicleInfo({ vehicleData }) {
	return (
		<Box
			sx={{
				padding: "16px 10px",
				width: "100%",
				boxSizing: "border-box",
			}}
		>
			{vehicleData?.map((vehicle, index) => (
				<Card
					key={index}
					sx={{
						marginBottom: "16px",
						width: "100%",
						backgroundColor: "var(--white)",
						border: "1px solid var(--light-green)",
						borderRadius: "6px",
					}}
				>
					<CardContent>
						<Typography
							variant="h6"
							component="div"
							sx={{ color: "var(--primary-green)" }}
						>
							{vehicle.registrationNumber}
						</Typography>
						<Typography
							variant="body1"
							component="div"
							sx={{ color: "var(--dark-gray)" }}
						>
							Vehicle Type: {vehicle.vehicleType}
						</Typography>
						<Typography
							variant="body2"
							component="div"
							sx={{ color: "var(--black)" }}
						>
							Emergency Contact: {vehicle.emergencyContact}
						</Typography>
					</CardContent>
				</Card>
			))}
		</Box>
	);
}
