import React, { useState } from "react";
import OTPInput from "otp-input-react";
import styles from "./style.module.css";
import apis from "../../assets/apis";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const OtpVerification = ({ phone, setPhone, otpApiBody, setOtpApiBody, setNotification }) => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const handleChange = (otp) => setOtp(otp);

  const verifyOtp = async () => {
    try {
      const response = await fetch(
        apis.VERIFY_OTP,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({...otpApiBody, verificationCode: otp }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if(data.jwtToken){
          setNotification({
            show: true,
            message: "OTP verified successfully",
            severity: 'success',
          });
          Cookies.set('jwtToken', data.jwtToken, { expires: 1 }); // Cookie expires in 1 day
          // Handle successful OTP verification
          setOtp('');
          setOtpApiBody(null);
          setPhone('');
          navigate('/user-portal');
        }else {
          setNotification({
            show: true,
            message: "Invalid OTP",
            severity: 'warning',
          });
        }
      } else {
        const data = await response.json();
        setNotification({
          show: true,
          message: data.message || "Error verifying OTP",
          severity: 'warning',
        });
      }
    } catch (error) {
      setNotification({
        show: true,
        message: "Network error",
        severity: 'warning',
      });
    }
  };

  return (
    <div className={styles.loginForm}>
      <div className={styles.heading1}>Enter the OTP</div>
      <div className={styles.heading2}>Enter the OTP sent to +91 - {phone}</div>
      <div className={styles.inputContainer}>
        <OTPInput
          value={otp}
          onChange={handleChange}
          autoFocus
          OTPLength={4}
          otpType="number"
          disabled={false}
          secure={false}
          inputStyles={{
            margin: "8px",
            textAlign: "center",
            fontSize: "16px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
          separator={<span>-</span>}
        />
      </div>
      <button className={styles.button_submit} type="submit" onClick={verifyOtp}>Verify OTP</button>
    </div>
  );
};

export default OtpVerification;
