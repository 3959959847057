// src/pages/TermsAndConditions/TermsAndConditions.jsx
import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import styles from "./TermsAndConditions.module.css"; // Add a CSS module for styles
import theme from "../../assets/theme"; // Import your MUI theme
import { ThemeProvider } from "@mui/material/styles"; // Wrap with ThemeProvider

const TermsAndConditions = () => {
	useEffect(() => {
		const logo = document.getElementById("logo");
		const hideLogo = () => {
			logo.style.display = "none";
		};
		setTimeout(hideLogo, 2000);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Box className={styles.termsWrapper}>
				<Box id="logo" className={styles.logo}>
					<img src={syfrLogo} alt="Syfr Logo" />
				</Box>
				<Typography variant="h4" gutterBottom>
					Terms and Conditions
				</Typography>
				<Typography variant="body1" component="div">
					<p>Welcome to Syfr!</p>
					<p>
						These terms and conditions outline the rules and
						regulations for the use of Syfr's Website, located at
						Syfr.live.
					</p>
					<p>
						By accessing this website, we assume you accept these
						terms and conditions. Do not continue to use Syfr if you
						do not agree to take all of the terms and conditions
						stated on this page.
					</p>
					{/* Include the rest of your terms and conditions content here */}
				</Typography>
			</Box>
		</ThemeProvider>
	);
};

export default TermsAndConditions;
