import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import VehicleInfo from "../../components/VehicleInfo/VehicleInfo";
import { Typography, Box, Container, Card, CardContent } from "@mui/material";
import CustomAlert from "../../components/CustomAlert/CustomAlert";
import apis from "../../assets/apis";
import theme from "../../assets/theme"; // Import your MUI theme
import { ThemeProvider } from "@mui/material/styles"; // Wrap with ThemeProvider
import styles from "./UserPortal.module.css";

const UserPortal = () => {
	const [vehicles, setVehicles] = useState([]);
	const [informCount, setInformCount] = useState(0);
	const [notification, setNotification] = useState({
		show: false,
		message: "",
		severity: "",
	});
	const navigate = useNavigate(); // Initialize navigate

	useEffect(() => {
		const token = Cookies.get("jwtToken");

		// Redirect to login if no token is found
		if (!token) {
			navigate("/login");
			return;
		}

		const fetchVehicles = async () => {
			try {
				const response = await fetch(apis.USER_PORTAL_INFO, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				console.log("line38", response.status, typeof response.status);
				// if (response.status === 401) {
				// 	// Redirect to login if the response status is 401 Unauthorized
				// 	navigate("/login");
				// 	return;
				// }

				const data = await response.json();
				if (response.ok) {
					setVehicles(data.userVehicles || []);
					setInformCount(data.informCount || 0);
				} else {
					setNotification({
						show: true,
						message: data.message || "Error fetching vehicles",
						severity: "warning",
					});
				}
			} catch (error) {
				console.log("err", error);
					// Redirect to login if the response status is 401 Unauthorized
					navigate("/login");
				
				setNotification({
					show: true,
					message: "Network error",
					severity: "warning",
				});
			}
		};

		fetchVehicles();
	}, [navigate]);

	const handleCloseNotification = () => {
		setNotification({ ...notification, show: false });
	};

	return (
		<ThemeProvider theme={theme}>
			{notification.show && (
				<CustomAlert
					message={notification.message}
					onClose={handleCloseNotification}
					severity={notification.severity}
				/>
			)}
			<Box sx={{ flexGrow: 1, bgcolor: "white", minHeight: "100vh" }}>
				<Container sx={{ py: 4 }}>
					<Box textAlign="center" mb={3}>
						<Typography variant="h4" color="primary">
							Syfr.live
						</Typography>
					</Box>

					{/* Cards Row */}
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							gap: 2,
							mb: 3,
						}}
					>
						<Card sx={{ minWidth: 150 }}>
							<CardContent>
								<Typography
									sx={{
										color: "grey.700",
										textAlign: "left",
										fontSize: 14,
									}}
								>
									Total Contacts
								</Typography>
								<Typography
									sx={{
										color: theme.palette.primary.main,
										textAlign: "left",
										fontSize: 36,
									}}
								>
									{informCount}
								</Typography>
							</CardContent>
						</Card>
						<Card sx={{ minWidth: 150 }}>
							<CardContent>
								<Typography
									sx={{
										color: "grey.700",
										textAlign: "left",
										fontSize: 14,
									}}
								>
									Registered Vehicles
								</Typography>
								<Typography
									sx={{
										color: theme.palette.primary.main,
										textAlign: "left",
										fontSize: 36,
									}}
								>
									{vehicles.length}
								</Typography>
							</CardContent>
						</Card>
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginX: "16px",
						}}
					>
						{vehicles.length > 0 ? (
							<VehicleInfo vehicleData={vehicles} />
						) : (
							<Typography variant="body1">
								No vehicles registered.
							</Typography>
						)}
					</Box>
				</Container>
			</Box>
		</ThemeProvider>
	);
};

export default UserPortal;
