import React, { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

export default function CustomAlert({ message, onClose, severity = 'warning', duration = 3000 }) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (duration > 0) {
      const timer = setTimeout(() => {
        setOpen(false);
        if (onClose) {
          onClose();
        }
      }, duration);
      return () => clearTimeout(timer); // Clear the timeout if the component unmounts or if duration changes
    }
  }, [duration, onClose]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
